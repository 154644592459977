p {
  margin: 0px;
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
}

.cursor-pointer {
  cursor: pointer;
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.755);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader img {
  width: 60px;
}

.back-button {
  position: fixed;
  top: 50px;
  left: 50px;
  cursor: pointer;
}

.wrapper {
  height: 100vh;
  /* overflow: hidden; */
}

.bubble {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: green;
  font-size: 10px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgb(134, 234, 184);
  color: black;
  font-weight: bold;
  border: 1px solid gray;
}

.min-50 {
  min-height: 50vh;
}

.nav-container {
  img {
    width: 100px;
    margin-right: 30px;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .navbar-nav {
    display: flex;
    align-items: center;
  }
  .navbar-nav:nth-child(2) {
    justify-content: center;
    flex-direction: row;
  }
  .nav-container {
    img {
      margin-right: 0px;
    }
  }
}

.bold {
  font-weight: bold;
}

.react-date-picker__wrapper {
  border: none;
}

.date-picker {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
}

.text-red {
  color: red;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.w-180 {
  width: 180px;
}

.font-24 {
  font-size: 24px;
}

.font-20 {
  font-size: 20px;
}

.font-18 {
  font-size: 18px;
}

.font-28 {
  font-size: 28px;
}

.font-gray {
  color: #787a79;
}

.font-red {
  color: red;
}
