.coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
    text-align: center;
    padding: 0px 10px;
    overflow: hidden;
  }
  
  .coming-soon-content {
    max-width: 600px;
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .coming-soon-content h1 {
    font-size: 3rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .coming-soon-content p {
    font-size: 1.2rem;
    color: #666;
  }
  
  .coming-soon-content p:before {
    content: "⏳";
    font-size: 2rem;
    margin-right: 10px;
  }
  