.custom-button-green {
  background-color: rgb(76, 175, 112);
  color: white;
}
.custom-button-green:hover {
  border: 1px solid gray;
}
.custom-button-gray {
  background-color: #6c757d;
  color: white;
}
.custom-button-gray:hover {
  border: 1px solid gray;
}
